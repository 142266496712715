<template>
    <div class="relative text-white-1">
        <div class="w-full absolute block z-20">
            <Header/>
        </div>
        <div class="space-top pn-blogs-banner-container w-full">
            <div class="relative h-full w-full flex justify-center items-start flex-col">
                <img class="absolute top-0 right-0 h-full object-cover z-5" src="../../../assets/images/home-page/pn-blogs/pn-blogs-banner.png" alt="Blogs Banner">
                <div class="flex flex-col mx-44 mobileL:mx-0 text-left mobileL:text-center z-10 max-w-xs lg:max-w-xs 2xl:max-w-md p-0 mobileL:p-4 
                ml-6 mobileL:ml-20 lg:ml-40 xl:ml-60 2xl:ml-80 relative mt-8 mobileL:-mt-16">
                    <div class="relative inline-block">
                        <span class="pn-blogs-bg-highlight absolute top-4 mobileL:top-8 lg:top-10 2xl:top-12 left-0 w-1/2 mobileL:w-full h-1/2 2xl:h-1/2 z-10"></span>
                        <img class="absolute -top-2 -left-2 mobileL:left-12 lg:left-6 2xl:left-12 2xl:top-1 w-3.5 mobileL:w-4 lg:w-5 z-0" src="../../../assets/images/home-page/shine-sm.png" alt="">
                        <div class="text-4xl md:text-6xl lg:text-7xl 2xl:text-9xl font-semibold relative z-20">
                            <h1 class="font-kannada font-semibold">Blogs</h1>
                        </div>
                        <img class="absolute -top-4 hidden mobileL:block -right-16 lg:-right-20 z-0 lg:w-28 2xl:w-auto w-24" src="../../../assets/images/home-page/sketch-line.png" alt="">
                    </div>
                    <p class="2xl:mt-6 text-sm sm:text-base md:text-lg lg:text-2xl 2xl:text-4xl mobileL:-mx-8 2xl:-mx-16 ">
                        Find beauty inspiration, the latest Passionails news, 
                        recommendations and more!
                    </p>
                </div>
            </div>
        </div>
        <div class="relative">
            <img class="w-full h-full object-cover" src="../../../assets/images/home-page/pn-blogs/pn-coming-soon.png" alt="Coming soon">
        </div>
        <div class="bg-green-15 py-4 mobileL:py-8 lg:py-12 2xl:py-24 text-white-1 relative">
            <img class="absolute top-0 -right-16 mobileL:right-0 w-60 mobileL:w-80 lg:w-100 xl:w-auto 2xl:w-120" src="../../../assets/images/home-page/pn-blogs/pn-signature-nails.png" alt="">
            <div class="text-center flex flex-col items-start mobileL:ml-28 2xl:ml-36 mobileLmb-4 relative">
                <div class="relative inline-block ml-4 mobileL:ml-0">
                    <span class="pn-signature-highlight absolute top-4 lg:top-8 2xl:top-8 left-0 w-full h-6 mobileL:h-1/5 lg:h-1/4 z-10"></span>
                    <div class="text-3xl mobileL:text-4xl lg:text-6xl 2xl:text-7xl font-semibold pb-1 mobileL:pb-6 relative z-20">
                        <h2 class="font-kannada font-semibold">Our Signature Nails</h2>
                    </div>
                    <p class="font-monsterrat text-xs font-montserrat mobileL::text-sm lg:text-xl xl:text-2xl 2xl:text-3xl">
                        Know what is our secret! <br> #Passionails #NailSalon #Philippines #Manila
                    </p>
                    <button class="rounded-full bg-pink-15 font-century text-white-1 mt-4 py-1 2xl:text-lg lg:py-2 px-6" @click="$router.push('/blog/our-signature-wax')">
                        Read More
                    </button>
                </div>
            </div>
        </div>
        <div class="w-full absolute block z-20">
            <Footer/>
        </div>
  </div>
</template>

<script>
import Header from '../../../components/navigation/LaybareHeader.vue'
import Footer from '../../../components/footer/PassionailsFooter.vue'

export default {
    components: {
        Header,
        Footer,
    },
    mounted() {
        this.$gtag.pageview('/passionails/blogs')
    },
}
</script>

<style>
.pn-blogs-banner-container {
    height: 700px;
    background-size: cover;
    background: linear-gradient(180deg, #dd9d9e 0%, #dd9d9e 100%);
}

@media screen and (min-width: 640px) and (max-width: 1023px) {
    .pn-blogs-banner-container {
        height: 45dvh;
    }
}

.space-top {
  margin-top: 90px;
}

@media (min-width: 1280px) {
  .space-top {
    margin-top: 100px;
    }
}

@media (min-width: 1536px) {
    .pn-blogs-banner-container {
      height: 110vh;
    }
    .space-top {
      margin-top: 128px;
    }
}

@media (max-width:480px) {
    .pn-blogs-banner-container{
        height:255px;
        background: #FFAEBB;
    }
    .space-top {
    margin-top: 60px;
    }
}

.pn-blogs-banner-title {
    color: #FFF;
    font-style: normal;
}

.pn-blogs-bg-highlight {
    position: absolute;
    background: #7D4979;
    left: 0px;
}

.pn-signature-highlight {
    position: absolute;
    background: #E1B6EB;
    left: 0px;
}

.shine-position {
    left:-8px
}
</style>